import { useWindowSize } from '@vueuse/core'
import { MobileView, type MobileViewKeys } from '@register'

export const useMobileViewStore = defineStore('mobileView', () => {
  // Determines if the PDF drawer is open on mobile
  const _showPdfOnMobile = ref<MobileViewKeys>(MobileView.GROUPS)
  function setViewOnMobile(view: MobileViewKeys) {
    _showPdfOnMobile.value = view
  }

  const windowSize = useWindowSize()
  const mobileView = computed(() => {
    if (windowSize.width.value < 768) {
      return _showPdfOnMobile.value
    }
  })

  return {
    mobileView,
    setViewOnMobile,
  }
})
